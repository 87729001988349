<template>
  <div class="account-settings-info-right">
    <div class="account-settings-info-title">
      <span>{{ selectedMenu.meta.title }}</span>
    </div>
    <a-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 1150 }"
      :columns="columns"
      :loading="ifSub"
      :pagination="false"
      :dataSource="tableData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <template slot="editable" slot-scope="text, record">
        <a-tag color="cyan" v-if="record.editable">可更改</a-tag>
        <a-tag color="#f50" v-else>不可更改</a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="default" shape="circle" icon="edit" size="small" @click="editClick(record)"></a-button>
      </template>
    </a-table>
    <a-modal
      title="参数编辑"
      v-model="visible"
      okText="编辑"
      cancelText="取消"
      :maskClosable="false"
      :confirmLoading="confirmButtonStatus"
      @ok="handleOk"
    >
      <params-form
        ref="editRef"
        type="edit"
        :editItem="currentData"
        @editSuccess="handleEditSuccess"
        @submitError="handleConfirmEnd"
        @submitStart="handleConfirmStart"
      ></params-form>
    </a-modal>
  </div>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import menuTile from '../../common/mixins/menuTitle'
import modalConfirmLoading from '../../common/mixins/modal-confirm-loading'
import ParamsForm from './ParamsForm'

export default {
  name: 'Params',
  mixins: [menuTile, modalConfirmLoading],
  data() {
    return {
      q: '',
      editable: { true: '可更改', false: '不能更改' },
      ifSub: true,
      visible: false,
      tableData: [],
      columns: [
        {
          title: '#',
          key: 'serial',
          scopedSlots: { customRender: 'serial' },
          width: 50,
        },
        {
          title: '配置Key',
          dataIndex: 'mapName',
          key: 'mapName',
          width: 120,
        },
        {
          title: '配置Value',
          dataIndex: 'mapValue',
          key: 'mapValue',
          width: 200,
        },
        {
          title: '值类型',
          dataIndex: 'valueType',
          key: 'valueType',
          width: 100,
        },
        {
          title: '描述',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: '可否更改',
          dataIndex: 'editable',
          key: 'editable',
          scopedSlots: { customRender: 'editable' },
          width: 100,
        },
        {
          title: '更新时间',
          dataIndex: 'lastUpdated',
          align: 'center',
          width: 120,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      currentData: {},
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    // ant-design 默认生成的表格 overflow-x 滚动为 scroll ，为了保证在没有滚动时不显示滚动条，修改为 auto
    // eslint-disable-next-line
    $('.ant-table-wrapper .ant-table-body').css('overflow-x', 'auto')
  },
  methods: {
    loadData() {
      this.$http(this, {
        url: SERVICE_URLS.system.search,
        noTips: true,
        data: {
          q: this.q,
        },
        success: (data) => {
          this.tableData = data.body
        },
      })
    },
    editClick(record) {
      this.visible = true
      this.$nextTick(() => {
        this.currentData = record
      })
    },
    handleEditSuccess(values) {
      Object.assign(this.currentData, values)
      this.confirmButtonStatus = false
      this.visible = false
    },
    handleOk() {
      this.$refs['editRef'].submitEdit()
    },
  },
  components: { ParamsForm },
}
</script>
